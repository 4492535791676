import React from 'react';
import {Col, Image, Row, Typography} from 'antd';
import {withTranslation} from 'react-i18next';
import request from "../../util/request";
import {getSession} from "../../util/sessionUtil";

const {Title, Paragraph} = Typography;

class CompanyIntroductionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnglish: true,
            companyInfo: {
                companyId: 1,
                description: 'loading',
                enDescription: 'loading',
                name: 'loading',
                enName: 'loading',
                fileVOList: [
                    {
                        fileId: 1,
                        url: ''
                    }
                ],
            },
            companyDetailList: [
                {
                    companyDetailId: 1,
                    companyId: 1,
                    detail: 'loading',
                    enDetail: 'loading',
                    fileVOList: [
                        {
                            fileId: 1,
                            url: ''
                        }
                    ]
                },
                {
                    companyDetailId: 1,
                    companyId: 1,
                    detail: 'loading',
                    enDetail: 'loading',
                    fileVOList: [
                        {
                            fileId: 1,
                            url: ''
                        }
                    ]
                },
                {
                    companyDetailId: 1,
                    companyId: 1,
                    detail: 'loading',
                    enDetail: 'loading',
                    fileVOList: [
                        {
                            fileId: 1,
                            url: ''
                        }
                    ]
                }
            ]
        }
    }

    initCompanyInfo() {
        request({
            url: '/dubai/query/company',
            method: 'get'
        }).then(res => {
            console.log('公司信息', res)
            this.setState({
                companyInfo: res.data,
            })
            this.initCompanyDetailList()
        })
    }

    initCompanyDetailList() {
        request({
            url: '/dubai/query/companyDetail',
            method: 'post'
        }).then(res => {
            console.log('公司详情', res)
            this.setState({
                companyDetailList: res.data,
            })
        })
    }

    componentDidMount() {
        this.initCompanyInfo()
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
    }

    render() {
        const {isEnglish} = this.state
        return (
            <div style={{maxWidth: '1000px', margin: '0 auto', padding: '20px'}}>
                <Row justify="center">
                    <Col span={24}>
                        <Title level={2} style={{textAlign: 'center'}}>
                            {isEnglish ? this.state.companyInfo.enName : this.state.companyInfo.name}
                        </Title>
                    </Col>
                </Row>
                {/* Company Global Layout */}
                <Row justify="center" style={{marginBottom: '30px'}}>
                    <Col span={24}>
                        <div style={{backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Image src={this.state.companyInfo.fileVOList[0].url} alt="Company Global Layout"
                                       width={'100%'}
                                       />
                            </div>
                            <Paragraph
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '30px',
                                    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n" +
                                        "  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n" +
                                        "  'Noto Color Emoji'",
                                    fontWeight: '400'
                                }}
                            >
                                {isEnglish?this.state.companyInfo.enDescription:this.state.companyInfo.description}
                            </Paragraph>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    {/* Company Basic Information */}
                    {
                        this.state.companyDetailList.map((item, index) => {
                            return (
                                <Col xs={24} md={12}>
                                    <div style={{backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px'}}>
                                        <Image src={item.fileVOList[0].url} alt="Company Logo"
                                               width={'100%'}/>
                                        <Paragraph
                                            style={{
                                                fontSize: '16px',
                                                lineHeight: '30px',
                                                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n" +
                                                    "  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n" +
                                                    "  'Noto Color Emoji'",
                                                fontWeight: '400'
                                            }}

                                        >
                                            {isEnglish?item.enDetail:item.detail}
                                        </Paragraph>
                                    </div>
                                </Col>
                            )
                        })
                    }

                </Row>
            </div>
        );
    }
}

export default withTranslation()(CompanyIntroductionPage);
