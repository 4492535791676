import React, {Component} from 'react';
import request from "../../util/request";
import 'quill/dist/quill.snow.css';
import {getSession} from "../../util/sessionUtil";

class Custom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            enContent: '',
            isEnglish: true,
            bgmColor: '#ffcb5e',
            videoUrl: '',
            title: ''
        }
    }


    componentDidMount() {
        // 获取路径
        const path = this.props.location
        console.log('path', path)
        if (path.pathname.includes('招聘') || path.pathname.includes('school')) {
            this.setState({
                bgmColor: '#ffcb5e'
            })
        } else {
            this.setState({
                bgmColor: '#f5f5f5'
            })
        }
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        request({
            url: '/dubai/query/menu/content',
            method: 'post',
            params: {
                path: path.pathname
            }
        }).then(res => {
            console.log('获取自定义菜单成功', res)
            this.setState({
                content: res.data.content,
                enContent: res.data.enContent,
                videoUrl: res.data.videoList[0].url,
                title: res.data.name
            })
        })

    }

    render() {
        return (
            <div
                style={{
                    maxWidth: '1000px',
                    margin: '20px auto',
                    padding: '20px',
                    border: '0px solid #f0f2f5',
                    borderRadius: '20px',
                    backgroundColor: this.state.bgmColor,
                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
                }}
                className="ql-snow"
            >
                <div
                    style={{
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <h1>{this.state.title}</h1>
                </div>

                {
                    this.state.videoUrl === '' ? null :
                        <div

                        >
                            <video src={this.state.videoUrl}
                                   width="100%" controls
                                   style={{
                                       border: '1px solid #f0f2f5',
                                       backgroundColor: '#000',
                                       borderRadius: '10px',
                                       boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
                                   }}
                            />
                        </div>
                }


                <div
                    className="ql-editor"
                    style={{
                        fontSize: '16px',
                        lineHeight: '30px',
                        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n" +
                            "  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n" +
                            "  'Noto Color Emoji'",
                        fontWeight: '400'
                    }}
                    dangerouslySetInnerHTML={{__html: this.state.isEnglish ? this.state.enContent : this.state.content}}/>
            </div>
        );
    }
}

export default Custom;
