import React, {useEffect} from 'react';
import {Image, Layout, Space, theme} from 'antd';
import {BrowserRouter as Router} from "react-router-dom";
import WkMenu from './pages/layout/menu/menu'
import { Col,Row} from 'antd';
import Footer from "./pages/layout/footer/footer";
import {WkRoute} from "./router/index";
import request from "./util/request";
import {getSession} from "./util/sessionUtil";

const { Content } = Layout;


const App = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [logo,setLogo] = React.useState('')
    const [background, setBackground] = React.useState(getSession('background') || colorBgContainer);


    const initFooter = () => {
        return request({
            url: '/dubai/query/footer/home',
            method: 'get',
        })
    }

    useEffect(() => {
        initFooter().then(res => {
            if (res.code === 200) {
                setLogo(res.data.fileVOList[0].url)
                // 把logo设置到session中
            }
        })


    }, [logo]);

    return (
        <Router>
        <Layout>
            <div style={{
                    width: '100%',
                    alignItems: 'center',
                    padding: '1rem 1.5rem',
                    background: background,

                    top: 0,
                }}>
                <Row style={{
                    alignItems: 'center'
                }}>
                    <Col  sm={24} md={{span:4,offset:4}}>
                        <div style={{
                            alignItems: 'center',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            color: '#000000E0',
                            fontFamily: 'sans-serif',
                            background: background,
                        }}>
                            <Space>
                                <div>
                                    <Image preview={false} src={logo} style={{height: '80px'}}/>
                                </div>
                            </Space>

                        </div>

                    </Col>
                    <Col sm={24} md={16}>
                        <WkMenu
                        />
                    </Col>
                </Row>


            </div>
            <div
            style={{

            }}>


            <Content
                style={{
                    background: '#fff',
                    margin: '1px auto 0px auto',
                    minHeight: 'calc(100vh - 400px)',
                }}
            >
                <div
                    style={{
                        background: colorBgContainer,
                    }}
                >
                        <WkRoute/>
                </div>
            </Content>
            <Footer
                style={{
                    background: colorBgContainer,
                }}
            />
            </div>
        </Layout>
        </Router>
    );
};
export default App;
