import React, {Component} from 'react';
import NewsBox from "./news-box/newsBox";
import {Pagination} from "antd";
import {getSession} from "../../util/sessionUtil";
import request from "../../util/request";

class News extends Component {
    state = {
        newsData: [
            {
                id: 1,
                title: 'loading',
                intro: 'loading',
                fileVOList: [
                    {
                        fileId: 1,
                        url: 'http://img.glasapphire.com/api/captcha/4644478eebe84b92af5ab8fa21c10d91.jpg',
                    }
                ]
            }
        ],
        currentPage: 1,
        itemsPerPage: 10,
        total: 0,
        isEnglish: true,
    };
    handlePageChange = (page) => {
        this.setState({ currentPage: page },()=>{
            this.search()
        });

    };

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.search()
    }

    search(){
        const data  = {
            current: this.state.currentPage,
            size: this.state.itemsPerPage,
            key: ''
        }
        request({
            url: '/dubai/query/news/list',
            method: 'post',
            data
        }).then(res =>{
            if(res.code === 200){
                this.setState({
                    newsData: res.data.records,
                    total: res.data.total
                })
            }
        })
    }

    render() {
        const { newsData, currentPage, itemsPerPage,isEnglish } = this.state;

        return (
            <div style={{
                marginTop: '20px'
            }}>
                {newsData.map((news,index) => (
                    <NewsBox key={index} id={news.newsId} title={isEnglish?news.enTitle:news.title}
                             intro={isEnglish?news.enDescription:news.description}
                             createTime={news.createTime} imageUrl={news.fileVOList[0].url}/>
                ))}
                <div style={{ textAlign: 'center',marginBottom: '10px' }}>
                    <Pagination
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={this.state.total}
                        showTotal={()=>{
                            return  'Total: ' + this.state.newsData.length
                        }}
                        showSizeChanger
                        hideOnSinglePage={true}
                        onChange={this.handlePageChange}
                    />
                </div>
            </div>
        );
    }
}

export default News;
