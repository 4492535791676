/*标题样式*/
export const titleStyle = {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bolder',
    color: '#000000E0',
    fontFamily: 'sans-serif',
}

/*首页公司产品模块颜色*/
export const colorBgProduct = '#F5F5F5';



