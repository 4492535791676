import React, {Component} from 'react';
import Meta from "antd/es/card/Meta";
import Card from "antd/es/card/Card";
import {colorBgProduct} from "../../../css/cssJs";
import {Col, Image, Row} from "antd";
import {withRouter} from "react-router-dom";
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";

/*产品排布样式：flex布局，横排分布，从左往右*/
const productStyle = {
    backgroundColor: colorBgProduct,
}

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList:[
                {
                    productId: 10001,
                    name: 'loading',
                    enName: 'loading',
                    fileVOList: [
                        {
                            fileId: 10001,
                            url: 'loading'
                        }
                    ]
                }
            ],
            isEnglish: true,
        };
    }

    initProduct(){
        return request('/dubai/query/product/home', {
            method: 'GET',
        })
    }

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initProduct().then((res) => {
            console.log('initProduct', res);
            if (res.code === 200) {
                this.setState({
                    productList: res.data,
                });
            }
        });
    }

    render() {
        return (
            <Row style={productStyle}>
                {
                    this.state.productList.map((item, index) => {
                        return (
                            <Col md={{span: 6}} xs={{span:24}} key={index}>
                            <Card
                                key={index}
                                hoverable
                                style={{
                                    padding: '20px 20px',
                                    margin: '20px 20px',
                                }}
                                onClick={()=>{
                                    console.log(item)
                                    this.props.history.push({
                                        pathname: '/product/Detail/'+ item.productId,
                                    })
                                }}
                                cover={<Image preview={false} alt="img"  src={item.fileVOList[0].url} />}
                            >
                                <Meta title={this.state.isEnglish?item.enName:item.name}
                                      style={{
                                          textAlign: 'center',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          display: '-webkit-box',
                                          WebkitLineClamp: 1,
                                          WebkitBoxOrient: 'vertical',
                                      }}
                                >
                                </Meta>
                            </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        );
    }
}

export default withRouter(Product);
