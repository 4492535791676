import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // 使用语言检测器
    .use(initReactI18next) // 使用react-i18next
    .init({
        fallbackLng: 'en', // 默认语言
        debug: true, // 开启调试模式
        resources: {
            en: {
                translation: {
                    // 中文翻译
                    // 顶部菜单
                    menu:{
                        company: '旺康沙特陶瓷有限公司',
                        home: '首页',
                        product: '产品中心',
                        news: '新闻中心',
                        about: '关于我们'
                    },
                    // 底部菜单
                    footer: {
                        linkTitle: '链接',
                        home: 'home',
                        product: 'product',
                        news: 'news',
                        about: 'about',
                        concatTitle: 'contact us',
                        email: 'email',
                        phone: 'phone',
                        address: 'address',
                        time: 'time',
                        sign: '© 2023 by 旺康沙特陶瓷有限公司'
                    },
                    // 产品中心
                    product: {
                        total: '全部',
                        title: '产品介绍',
                        searchPlaceholder: '请输入搜索条件',
                        ways: '用途',
                        works: '工艺',
                        series: '规格',
                    },
                    // 关于我们
                    about: {
                        title: '关于我们',
                        content: '旺康沙特陶瓷有限公司是一家专业生产陶瓷的公司，公司位于沙特阿拉伯，公司主要生产陶瓷砖，陶瓷马赛克，陶瓷卫浴等产品，公司产品主要销往沙特阿拉伯，也有少量产品销往其他国家。公司产品质量好，价格合理，欢迎广大客户前来咨询。',
                    },
                    // 产品详情
                    productDetail: {
                        title: 'detail',
                        name: '',
                        ways: '用途',
                        works: '工艺',
                        series: '规格',
                        introduce: '产品介绍',
                    }
                },
            },
            zh: {
                translation: {
                    // 中文翻译
                    // 顶部菜单
                    menu:{
                        company: '旺康沙特陶瓷有限公司',
                        home: '首页',
                        product: '产品中心',
                        news: '新闻中心',
                        about: '关于我们'
                    },
                    // 底部菜单
                    footer: {
                        linkTitle: '链接',
                        home: '首页',
                        product: '产品中心',
                        news: '新闻中心',
                        about: '关于我们',
                        concatTitle: '联系我们',
                        email: '电子邮箱',
                        phone: '联系电话',
                        address: '联系地址',
                        time: '营业时间',
                        sign: '© 2023 by 旺康沙特陶瓷有限公司'
                    },
                    // 产品中心
                    product: {
                        total: '全部',
                        title: '产品介绍',
                        searchPlaceholder: '请输入搜索条件',
                        ways: '用途',
                        works: '工艺',
                        series: '规格',
                    },
                    // 关于我们
                    about: {
                        title: '关于我们',
                        content: '旺康沙特陶瓷有限公司是一家专业生产陶瓷的公司，公司位于沙特阿拉伯，公司主要生产陶瓷砖，陶瓷马赛克，陶瓷卫浴等产品，公司产品主要销往沙特阿拉伯，也有少量产品销往其他国家。公司产品质量好，价格合理，欢迎广大客户前来咨询。',
                        },
                    // 产品详情
                    productDetail: {
                        title: '产品介绍',
                        name: '名称',
                        ways: '用途',
                        works: '工艺',
                        series: '规格',
                        introduce: '产品介绍',
                    }
                },
            },
            ar: {
                translation: {
                    // 阿拉伯翻译
                    // 顶部菜单
                    menu:{
                        company: 'شركة وانغ كانغ السعودية للسيراميك المحدودة',
                        home: 'الصفحة الأمامية',
                        product: 'مركز المنتج',
                        news: 'مركز الأخبار',
                        about: 'معلومات عنا'
                    },
                    // 底部菜单
                    footer: {
                        linkTitle: 'وصلة',
                        home: 'الصفحة الأمامية',
                        product: 'مركز المنتج',
                        news: 'مركز الأخبار',
                        about: 'معلومات عنا',
                        concatTitle: 'اتصل بنا',
                        email: 'بريد إلكتروني',
                        phone: 'رقم الاتصال',
                        address: 'عنوان الإتصال',
                        time: 'عنوان الإتصال',
                        sign: '© 2023 by شركة وانغ كانغ السعودية للسيراميك المحدودة'
                    },
                    // 产品中心
                    product: {
                        searchPlaceholder: 'الرجاء إدخال معايير البحث',
                        ways: 'يستخدم',
                        works: 'حرفة',
                        series: 'تخصيص',
                    },
                    // 关于我们
                    about: {
                        title: 'معلومات عنا',
                        content: '产陶瓷砖，陶瓷马赛克，陶瓷卫浴等产品，公司产品主要销往沙特阿拉伯，也有少量产品销往其他国家。公司产品质量好，价格合理，欢迎广大客户前来咨询。',
                    }
                },
            },
        },
        interpolation: {
            escapeValue: false, // React不需要转义HTML标签
        },
    });

export default i18n;
