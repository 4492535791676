import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Button, Col, Descriptions, Image, Row, Tag} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../../../css/icon.css';
import { withTranslation } from 'react-i18next';
import request from "../../../util/request";
import {getSession} from "../../../util/sessionUtil";

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: this.props.match.params.id,
            isEnglish: true,
            attrs: '',
            currentImageIndex: 0, // 当前显示的图片索引

            productDetail: {
                productId: 10001,
                name: 'loading',
                enName: 'loading',
                description: 'loading',
                enDescription: 'loading',
                fileVOList: [
                    {
                        fileId: 1,
                        url: ''
                    }],
                attributes: [
                    {
                        attributeId: 1,
                        name: 'loading',
                        enName: 'loading'
                    }
                ]
            }
        };
    }

    initProductDetail(){
        const { productId } = this.state;
        request(`/dubai/query/product/${productId}`, {
            method: 'post'
        }).then((res) => {
            if (res.code === 200) {
                this.setState({
                    productDetail: res.data,
                });
                this.getAttributes(res.data.attributes)
            }
        });
    }

    componentDidMount() {
        this.setState({
            isEnglish: getSession('language') === 'en',
        })
        this.initProductDetail()
    }

    getAttributes(attrs){
        let str = ''
        attrs.forEach((item) => {
            if (this.state.isEnglish){
                str = str + item.enName + ','
            }else {
                str = str + item.name+ ','
            }

        })
        this.setState({
            attrs: str
        })
    }

    handleImageClick = (index) => {
        this.setState({ currentImageIndex: index });
    };

    handleArrowClick = (direction) => {
        const { currentImageIndex,productDetail } = this.state;
        const maxIndex = productDetail.fileVOList.length - 1; // 最大图片索引
        if (direction === 'left') {
            this.setState({ currentImageIndex: currentImageIndex === 0 ? maxIndex : currentImageIndex - 1 });
        } else if (direction === 'right') {
            this.setState({ currentImageIndex: currentImageIndex === maxIndex ? 0 : currentImageIndex + 1 });
        }
    };

    render() {
        const { t } = this.props;
        const { currentImageIndex } = this.state;

        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    padding: '50px 0'

                }}
            >
                <Row>
                    <Col md={{ span: 8, offset: 4 }}>
                        <Button style={{marginBottom: '20px'}}  onClick={() => this.props.history.goBack()}><LeftOutlined />back</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 24, offset: 0 }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f5',
                                padding: '20px 30px',
                                borderRadius: '10px',
                            }}
                        >
                            <Image
                                maxWidth={'800px'}
                                height={'100%'}
                                src={
                                    this.state.productDetail.fileVOList[currentImageIndex].url
                                }
                                alt="产品图片"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <LeftOutlined
                                    className="antd-icon"
                                    onClick={() => this.handleArrowClick('left')}
                                    style={{
                                        fontSize: '30px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        color: '#000000A6',
                                    }}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        whiteSpace: 'nowrap',
                                        overflowX: 'scroll',
                                    }}
                                >
                                    {this.state.productDetail.fileVOList.map((item, index) => (
                                        <Image
                                            key={index}
                                            preview={false}
                                            onClick={() => this.handleImageClick(index)}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                margin: '10px 10px 0 0',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'start',
                                                alignContent: 'start',
                                                borderRadius: '5px',
                                                border: currentImageIndex === index ? '2px solid #1890ff' : 'none', // 添加边框样式
                                            }}
                                            src={item.url}
                                            alt="产品图片"
                                        />
                                    ))}
                                </div>
                                <RightOutlined
                                    className="antd-icon"
                                    onClick={() => this.handleArrowClick('right')}
                                    style={{
                                        fontSize: '30px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        color: '#000000A6',
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={{ span: 24, offset: 1 }}>
                        <Descriptions title={t('productDetail.title')} column={1}>
                            {/* 其他描述信息 */}
                            {/*<Descriptions.Item label={t('productDetail.name')}>*/}
                            {/*    {this.state.isEnglish?this.state.productDetail.enName:this.state.productDetail.name}*/}
                            {/*</Descriptions.Item>*/}
                            {
                                this.state.productDetail.attributes.length>0 ?
                                    <Descriptions.Item label={t('productDetail.ways')}>{
                                        this.state.productDetail.attributes.map((item) => {
                                            return (
                                                <div>
                                                    <Tag color="blue">{this.state.isEnglish?item.enName:item.name}</Tag>
                                                </div>
                                            )
                                        })
                                    }</Descriptions.Item>
                                    : null
                            }

                            <Descriptions.Item >
                                <div dangerouslySetInnerHTML={{__html: this.state.isEnglish?this.state.productDetail.enDescription:this.state.productDetail.description}}/>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(withRouter(ProductDetail));
