import React from 'react';
import { Result, Button } from 'antd';
import { withRouter } from 'react-router-dom';

class NotFound extends React.Component {
    handleBack = () => {
        this.props.history.push('/');
    };

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary" onClick={this.handleBack}>Back to Home</Button>}
                />
            </div>
        );
    }
}

export default withRouter(NotFound);
