/**
 * session工具类
 */

/**
 * 获取session
 * @param key
 * @returns {null|any}
 */
export function getSession(key) {
  const value = sessionStorage.getItem(key)
  if (value) {
    return JSON.parse(value)
  }
  return null
}

/**
 * 设置session
 * @param key
 * @param value
 */
export function setSession(key, value) {
  return sessionStorage.setItem(key, JSON.stringify(value))
}

/**
 * 清空session
 */
export function clearSession() {
  return sessionStorage.clear()
}
